import React, { useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import useQuickTranslator from '../../hooks/useQuickTranslator'
import NetlifiedForm from './NetlifiedForm'

/**
 * @param {string} name
 * @returns {JSX.Element}
 * @constructor
 */
const ContactForm = ({ name }) => {
    const t = useQuickTranslator()

    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const submitHandler = e => {
        setError(false)
        setLoading(true)

        let formData = new FormData(e.target)

        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: new URLSearchParams(formData).toString(),
        })
            .then(response => {
                if (!response.ok) {
                    setError(t('contactform.error'))
                    return
                }

                setSubmitted(true)
            })
            .catch(() => setError(t('contactform.error')))
            .finally(() => setLoading(false))
    }

    return !submitted ? (
        <>
            {error && <Alert variant='danger'>{error}</Alert>}
            <NetlifiedForm
                onSubmit={submitHandler}
                className='mb-5'
                name={name}
                subject='Contact request - Siru Mobile'>
                <Form.Group controlId='name'>
                    <Form.Label srOnly>
                        <FormattedMessage id='contactform.name' />
                    </Form.Label>
                    <Form.Control type='text' placeholder={t('contactform.name')} name='name' className='px-4' />
                </Form.Group>
                <Form.Group controlId='phone'>
                    <Form.Label srOnly>
                        <FormattedMessage id='contactform.phone' />
                    </Form.Label>
                    <Form.Control
                        type='text'
                        placeholder={t('contactform.phone')}
                        name='phone'
                        className='px-4'
                        required
                    />
                </Form.Group>
                <Form.Group controlId='email'>
                    <Form.Label srOnly>
                        <FormattedMessage id='contactform.email' />
                    </Form.Label>
                    <Form.Control
                        type='email'
                        placeholder={t('contactform.email')}
                        name='email'
                        className='px-4'
                        required
                    />
                </Form.Group>
                <Form.Group controlId='message'>
                    <Form.Label srOnly>
                        <FormattedMessage id='contactform.message' />
                    </Form.Label>
                    <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder={t('contactform.message')}
                        name='message'
                        className='px-4'
                        required
                    />
                </Form.Group>

                <Row className='d-flex justify-content-end'>
                    <Col lg='5'>
                        <Button variant='success' type='submit' block disabled={loading} className='text-uppercase'>
                            <FormattedMessage id='contactform.submit' />
                        </Button>
                    </Col>
                </Row>
            </NetlifiedForm>
        </>
    ) : (
        <div className='text-center'>
            <span className='h1 mb-3 d-block'>
                <FormattedMessage id='contactform.thankyou.h' />
            </span>
            <p className='mb-4'>
                <FormattedMessage id='contactform.thankyou.p' />
            </p>
        </div>
    )
}

export default ContactForm
