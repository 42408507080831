import styled from 'styled-components'
import { Col } from 'react-bootstrap'

const StyledSideCol = styled(Col)`
    .top {
        font-size: 1.2rem;
        letter-spacing: 1px;
    }
    .bottom {
        font-size: 1.75rem;
    }
`

export default StyledSideCol
