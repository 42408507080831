import React from 'react'
import SEOTools from '../../components/SEOTools'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import HeroUnit from '../../components/HeroUnit'
import StyledSideCol from '../../components/styled/StyledSideCol'
import ContactForm from '../../components/Forms/ContactForm'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const MerchantSupportPage = ({ data }) => {
    const devLookingForSupportBg = getImage(data.devLookingForSupportBg)

    return (
        <Layout>
            <SEOTools title='Merchant support' description='Need some help on integrating Siru? We are here for you.' />

            <HeroUnit backgroundImage={data.heroBg} className='dark-background-overlay'>
                <Row className='d-flex align-items-center justify-content-center text-center text-white my-5'>
                    <Col md='8'>
                        <h1>Merchant support</h1>
                    </Col>
                </Row>
            </HeroUnit>

            <section>
                <Container>
                    <Row className='mt-5 mb-4 pt-5'>
                        <StyledSideCol md='4' className='mb-3'>
                            <div className='text-secondary top'>Help us</div>
                            <div className='h3 bottom'>
                                <strong>Help your business</strong>
                            </div>
                        </StyledSideCol>
                        <Col>
                            <p>
                                What can we do for you today? Do not hesitate to contact us, we are here for you. Let us
                                know how we can help and we will get back to you. You can also check out the rest of our
                                contact information on our <Link to='/contact'>Contact page</Link>.
                            </p>
                        </Col>
                    </Row>

                    <ContactForm name='merchant-support-contact' />
                </Container>
            </section>

            <section className='dark-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0'>
                    <Container>
                        <Row className='d-flex align-items-center justify-content-center my-5 py-5'>
                            <Col md='10'>
                                <h2>Developer looking for support?</h2>
                                <p className='mb-5 spaced-light'>
                                    Want to know how you can slap Siru API into your system? Well just check out our
                                    developer docs. We even have a chat for you, so you can straight up talk to our dev
                                    team.
                                </p>
                                <a
                                    href='/developers'
                                    target='_blank'
                                    rel='noopener'
                                    className='btn btn-success btn-lg px-5 rounded-0'>
                                    Show me the docs
                                </a>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={devLookingForSupportBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>
        </Layout>
    )
}

/**
 * Page query can be dynamic with variables. Can only be run on a page level.
 */
export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "sirumobile_merchant_support.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: SOUTH, fit: OUTSIDE }
                )
            }
        }
        devLookingForSupportBg: file(relativePath: { eq: "sirumobile_merchantsupport_developer.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: SOUTH, fit: OUTSIDE }
                )
            }
        }
    }
`

export default MerchantSupportPage
